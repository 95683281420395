// Generated by Framer (6113280)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {obfqcACdH: {hover: true}};

const cycleOrder = ["obfqcACdH"];

const variantClassNames = {obfqcACdH: "framer-v-2apy91"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "obfqcACdH", image: jihnxg9gN = {src: new URL("assets/cZgc9NyDlEhmg6hDBJHf2mKlfDY.svg", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "obfqcACdH", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gbQuz", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-2apy91", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"obfqcACdH"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"obfqcACdH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 220, intrinsicWidth: 191, pixelHeight: 220, pixelWidth: 191, ...toResponsiveImage(jihnxg9gN)}} className={"framer-16mo7ce"} data-framer-name={"image"} layoutDependency={layoutDependency} layoutId={"Y0OeqUzLD"} style={{boxShadow: "none"}} transition={transition} variants={{"obfqcACdH-hover": {boxShadow: "0px 0.6021873017743928px 0.6021873017743928px -1.25px rgba(0, 0, 0, 0.17997), 0px 2.288533303243457px 2.288533303243457px -2.5px rgba(0, 0, 0, 0.15889), 0px 10px 10px -3.75px rgba(0, 0, 0, 0.0625)"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gbQuz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gbQuz .framer-edse3q { display: block; }", ".framer-gbQuz .framer-2apy91 { height: 243px; overflow: visible; position: relative; width: 194px; }", ".framer-gbQuz .framer-16mo7ce { aspect-ratio: 0.7987421383647799 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 243px); left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-gbQuz .framer-v-2apy91 .framer-2apy91 { cursor: pointer; }", ".framer-gbQuz.framer-v-2apy91.hover .framer-16mo7ce { height: var(--framer-aspect-ratio-supported, 293px); left: -20px; overflow: visible; right: -20px; top: -80px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 243
 * @framerIntrinsicWidth 194
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mx46COTsH":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jihnxg9gN":"image"}
 */
const FramerQseGxjqRn: React.ComponentType<Props> = withCSS(Component, css, "framer-gbQuz") as typeof Component;
export default FramerQseGxjqRn;

FramerQseGxjqRn.displayName = "ziele-1";

FramerQseGxjqRn.defaultProps = {height: 243, width: 194};

addPropertyControls(FramerQseGxjqRn, {jihnxg9gN: {__defaultAssetReference: "data:framer/asset-reference,cZgc9NyDlEhmg6hDBJHf2mKlfDY.svg?originalFilename=icn-drive-more-traffic.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerQseGxjqRn, [])